import { createStore } from "redux";
import { persistStore } from "redux-persist";
import reducer from "./reducer";
import { applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

//-----------------------|| REDUX - MAIN STORE ||-----------------------//

const store = createStore(reducer, applyMiddleware(thunkMiddleware));
const persister = persistStore(store);

export { store, persister };
