// userRoles.js

const userRoles = {
    superAdmin: 0,
    user: 1,
    admin: 2,
    systemAdmin: 3,
    masterViewer: 4,
    supportAdmin: 5,
  };
  export default userRoles;
  