import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

function SessionExpiredModal({ isOpen, onRequestClose, onLogout }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Session Expired Modal"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "column",
          width: "100%",
          height: "50vh",
          alignItems: "center",
        }}
      >
        <p style={{ fontWeight: 200, fontSize: 20 }}>
          Your session has ended either due to inactivity or because you logged
          in on another device.{" "}
        </p>
        <p>Click the "Refresh" button below to return to the login page.</p>
        <button
          className="btn btn-primary"
          onClick={onLogout}
          style={{ width: "200px", fontWeight: 900 }}
        >
          Refresh
        </button>
      </div>
    </Modal>
  );
}

export default SessionExpiredModal;
