// userRoles.js

const dashboardType = {
    ems: 0,
    wms: 1,
    dg: 2,
    bms: 3,
    temp: 4,
    hvc: 5,
    vib:6,
    fms: 7,
    nbs: 8,
    trans: 9,
    supportAdmin:98,
    admin: 99,
    superAdmin: 100,
  };
  export default dashboardType;
  