import { useSelector } from "react-redux";
import urjayLogo from "./../../src/assets/images/comapnyLogo/urjay.svg";
import nbLogo from "./../../src/assets/images/comapnyLogo/NLogo.svg";
import ppLogo from "./../../src/assets/images/comapnyLogo/PPLogo.svg";
import dwellLogo from "./../../src/assets/images/comapnyLogo/dwellLogo.svg";
import { goBackToAdminView } from "../store/actions";
import { useDispatch } from "react-redux";

const Logo = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);

  let logoSource;
  let logoStyle = {
    width: 72,
    height: 40,
  };

  // Determine which logo to display and its specific style
  if (account.dashboard_logo === 1) {
    logoSource = urjayLogo;
    logoStyle.width = "100";
    logoStyle.height = 100;
  } else if (account.dashboard_logo === 2) {
    logoSource = ppLogo;
    logoStyle.width = "100%";
    logoStyle.height = 35;
  } else if (account.dashboard_logo === 3) {
    logoSource = dwellLogo;
    logoStyle.width = 60;
    logoStyle.height = 50;
  } else {
    logoSource = nbLogo;
  }

  const handleClick = () => {
    if (account.admin != null) {
      dispatch(goBackToAdminView());
    }
  };

  return (
    <img
      src={logoSource}
      style={logoStyle}
      rel="preload"
      fetchpriority="high"
      alt="NBSense"
      onClick={handleClick}
    />
  );
};

export default Logo;
