import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import {
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Slider,
  Typography,
  Radio,
} from "@material-ui/core";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import "@fontsource/red-hat-display";
// import "@fontsource/red-hat-display/400.css";
// project imports
import SubCard from "../../ui-component/cards/SubCard";
import { SET_BORDER_RADIUS, SET_FONT_FAMILY } from "../../store/actions"; // THEME_RTL
import { gridSpacing } from "../../store/constant";

// concat 'px'
function valueText(value) {
  return `${value}px`;
}

//-----------------------|| LIVE CUSTOMIZATION ||-----------------------//

const Customization = () => {
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);

  // drawer on/off
  const [open, setOpen] = React.useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  // state - border radius
  const [borderRadius, setBorderRadius] = React.useState(
    customization.borderRadius
  );
  const handleBorderRadius = (event, newValue) => {
    setBorderRadius(newValue);
  };

  useEffect(() => {
    dispatch({ type: SET_BORDER_RADIUS, borderRadius: borderRadius });
  }, [dispatch, borderRadius]);

  const initialFont = 'Red Hat Display';

  // state - font family
  const [fontFamily, setFontFamily] = React.useState(initialFont);
  useEffect(() => {
    dispatch({ type: SET_FONT_FAMILY, fontFamily: fontFamily });
  }, [dispatch, fontFamily]);

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        onClose={handleToggle}
        open={open}
        PaperProps={{
          sx: {
            width: 280,
          },
        }}
      >
        <PerfectScrollbar component="div">
          <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
            <Grid item xs={12}>
              {/* font family */}
              <SubCard title="Font Family">
                <FormControl>
                  <RadioGroup
                    aria-label="font-family"
                    value={fontFamily}
                    onChange={(e) => setFontFamily(e.target.value)}
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Red Hat Display"
                      control={<Radio />}
                      label="Red Hat Display"
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 28 },
                        "& .MuiFormControlLabel-label": { color: "grey.900" },
                      }}
                    />
                    <FormControlLabel
                      value="Red Hat Display"
                      control={<Radio />}
                      label="Red Hat Display"
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 28 },
                        "& .MuiFormControlLabel-label": { color: "grey.900" },
                      }}
                    />
                    <FormControlLabel
                      value="Red Hat Display"
                      control={<Radio />}
                      label="Red Hat Display"
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 28 },
                        "& .MuiFormControlLabel-label": { color: "grey.900" },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </SubCard>
            </Grid>
            <Grid item xs={12}>
              {/* border radius */}
              <SubCard title="Border Radius">
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  alignItems="center"
                  sx={{ mt: 2.5 }}
                >
                  <Grid item>
                    <Typography variant="h6" color="secondary">
                      4px
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Slider
                      size="small"
                      value={borderRadius}
                      onChange={handleBorderRadius}
                      getAriaValueText={valueText}
                      valueLabelDisplay="on"
                      aria-labelledby="discrete-slider-small-steps"
                      marks
                      step={2}
                      min={4}
                      max={24}
                      color="secondary"
                      sx={{
                        "& .MuiSlider-valueLabel": {
                          color: "secondary.light",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="secondary">
                      24px
                    </Typography>
                  </Grid>
                </Grid>
              </SubCard>
            </Grid>
          </Grid>
        </PerfectScrollbar>
      </Drawer>
    </React.Fragment>
  );
};

export default Customization;
