import {
  bms_dashboard,
  temp_dashboard,
  ems_dashboard,
  dg_dashboard,
  fms_dashboard,
  hvc_dashboard,
  vib_dashboard,
  wms_dashboard,
  nbs_dashboard,
  trans_dashboard,
  superAdmin_dashboard,
  support_dashboard,
  useAdminDashboard,
} from "./ems_dashboard";

// Custom hook to generate menu items
export const useMenuItems = () => {

  const adminDashboard = useAdminDashboard();

  return {
    items: [ems_dashboard],
    dg_items: [dg_dashboard],
    bms_items: [bms_dashboard],
    wms_items: [wms_dashboard],
    temp_items: [temp_dashboard],
    fms_items: [fms_dashboard],
    hvc_items: [hvc_dashboard],
    vib_items: [vib_dashboard],
    nbs_items: [nbs_dashboard],
    trans_items: [trans_dashboard],
    superAdmin_items: [superAdmin_dashboard],
    admin_items: [adminDashboard],
    support_items: [support_dashboard],
  };
};
