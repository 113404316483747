import React, {lazy, useEffect, useState} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {logOut} from '../store/actions';
// project imports
import MainLayout from './../layout/MainLayout';


import SubscriptionErrorPage from '../views/dashboard/SubscriptionErrorPage'
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

import SessionExpiredModal from '../views/utilities/modals/SessionExpiredModal';
import SessionPausedMessage from '../SessionPausedMessage';

import configData from '../config';
import axios from "axios";
import dashboardType from "../store/dashboard_type";

//Super Admin Dashboard Starts Here
const SuperAdminPage = Loadable(lazy(() => import('../views/dashboard/superAdmin/HomePage')));
const SuperAdminUser = Loadable(lazy(() => import('../views/dashboard/superAdmin/superAdminUser')));
const SuperAdminDevice = Loadable(lazy(() => import('../views/dashboard/superAdmin/deviceClient')));
const SuperAdminMeter = Loadable(lazy(() => import('../views/dashboard/superAdmin/addMeter')));
const SuperAdminImei = Loadable(lazy(() => import('../views/dashboard/superAdmin/superAdminImei')));
const SuperAdminMeterAccess = Loadable(lazy(() => import('../views/dashboard/superAdmin/MeterAccess')));
const SuperAdminWms = Loadable(lazy(() => import('../views/dashboard/superAdmin/superAdminWmsConfig')));
const SuperAdminDg = Loadable(lazy(() => import('../views/dashboard/superAdmin/superAdminDgConfig')));
const SuperAdminBms = Loadable(lazy(() => import('../views/dashboard/superAdmin/superAdminBmsConfig')));
const SuperAdminTemp = Loadable(lazy(() => import('../views/dashboard/superAdmin/superAdminTempConfig')));
const SuperAdminHvac = Loadable(lazy(() => import('../views/dashboard/superAdmin/superAdminHvac')));
const SuperAdminHooter = Loadable(lazy(() => import('../views/dashboard/superAdmin/superAdminHooterConfig')));
const SuperAdminView = Loadable(lazy(() => import('../views/dashboard/superAdmin/deviceView')));
const SuperAdminClientView = Loadable(lazy(() => import('../views/dashboard/superAdmin/superAdminClientView')));
const SuperAdminSiteStatus = Loadable(lazy(() => import('../views/dashboard/superAdmin/siteStatus')));
const SuperAdminHelpDesk = Loadable(lazy(() => import('../views/dashboard/superAdmin/helpDeskAdmin')));
const SuperAdminBroadcast = Loadable(lazy(() => import('../views/dashboard/superAdmin/broadcast')));
//Super Admin Dashboard Ends Here

//Admin Dashboard Starts Here
const AdminPage = Loadable(lazy(() => import('../views/dashboard/admin/HomePage')));
const AdminUser = Loadable(lazy(() => import('../views/dashboard/admin/adminUser')));
const AdminMeter = Loadable(lazy(() => import('../views/dashboard/admin/addMeter')));
const AdminClientView = Loadable(lazy(() => import('../views/dashboard/admin/adminClientView')));
const AdminSiteView = Loadable(lazy(() => import('../views/dashboard/admin/siteStatus')));
const AdminHelpDesk = Loadable(lazy(() => import('../views/dashboard/admin/helpDeskAdmin')));
const AdminBroadcast = Loadable(lazy(() => import('../views/dashboard/admin/broadcast')));
const AdminNBAudit = Loadable(lazy(() => import('../views/dashboard/admin/nbaudit')));
//Admin Dashboard Ends Here

// EMS Dashboard starts here
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/ems')));
const UtilsAnalysis = Loadable(lazy(() => import('../views/utilities/Analysis')));
// const UtilsComparison = Loadable(lazy(() => import('../views/utilities/MeterCompare')));
const UtilsMnv = Loadable(lazy(() => import('../views/utilities/MnV')));
const UtilsAlarms = Loadable(lazy(() => import('../views/utilities/Alarms')));
const UtilsAlarmsList = Loadable(lazy(() => import('../views/utilities/AlarmsList')));
const UtilsReportGen = Loadable(lazy(() => import('../views/utilities/ReportGeneration')));
const UtilsKpi = Loadable(lazy(() => import('../views/utilities/Kpi')));
const UtilsKpiBill = Loadable(lazy(() => import('../views/utilities/KpiBill')));
const UtilsGhg = Loadable(lazy(() => import('../views/utilities/EmissionCal')));
const UtilsSupport = Loadable(lazy(() => import('../views/utilities/HelpDesk')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));
// EMS Dashboard ends here

// Transformer Dashboard starts here
const TransformerDashboard = Loadable(lazy(() => import('../views/dashboard/trans')));
const TransformerHelpDesk = Loadable(lazy(() => import('../views/utilities/HelpDesk')));
// Transformer Dashboard ends here

// Water Dashboard starts here
const WMSDashboardDefault = Loadable(lazy(() => import('../views/dashboard/wms')));
const WMSAnalysis = Loadable(lazy(() => import('../views/dashboard/wms/Analysis')));
const WMSAlarms = Loadable(lazy(() => import('../views/dashboard/wms/WMSAlarms')));
const WMSAlarmsList = Loadable(lazy(() => import('../views/dashboard/wms/WMSAlarmsList')));
const WMSHelpDesk = Loadable(lazy(() => import('../views/utilities/HelpDesk')));
// Water Dashboard ends here

// DG Dashboard starts here
const DGDashboardDefault = Loadable(lazy(() => import('../views/dashboard/dg')));
const DGAnalysis = Loadable(lazy(() => import('../views/dashboard/dg/Analysis')));
const DGAlarms = Loadable(lazy(() => import('../views/dashboard/dg/DGAlarms')));
const DGAlarmsList = Loadable(lazy(() => import('../views/dashboard/dg/DGAlarmsList')));
const DGHelpDesk = Loadable(lazy(() => import('../views/utilities/HelpDesk')));
// DG Dashboard ends here

// BMS Dashboard starts here
const BMSDashboardDefault = Loadable(lazy(() => import('../views/dashboard/bms')));
const BMSAnalysis = Loadable(lazy(() => import('../views/dashboard/bms/analysis')));
const BMSAlarms = Loadable(lazy(() => import('../views/dashboard/bms/BMSAlarms')));
const BMSAlarmsList = Loadable(lazy(() => import('../views/dashboard/bms/BMSAlarmsList')));
const BMSHelpDesk = Loadable(lazy(() => import('../views/utilities/HelpDesk')));
// BMS Dashboard ends here

// TEMP Dashboard starts here
const TEMPDashboardDefault = Loadable(lazy(() => import('../views/dashboard/temp')));
const TEMPAnalysis = Loadable(lazy(() => import('../views/dashboard/temp/analysis')));
const TEMPAlarms = Loadable(lazy(() => import('../views/dashboard/temp/TEMPAlarms')));
const TEMPAlarmsList = Loadable(lazy(() => import('../views/dashboard/temp/TEMPAlarmsList')));
const TEMPHooterList = Loadable(lazy(() => import('../views/dashboard/temp/TEMPHooterList')));
const TempReport = Loadable(lazy(() => import('../views/dashboard/temp/TempReport')));
const TempHelpDesk = Loadable(lazy(() => import('../views/utilities/HelpDesk')));
// TEMP Dashboard ends here

// Fire Dashboard starts here
const FMSDashboardDefault = Loadable(lazy(() => import('../views/dashboard/fms')));
const FMSHelpDesk = Loadable(lazy(() => import('../views/utilities/HelpDesk')));
// Fire Dashboard ends here

// HVAC Dashboard starts here
const HVCDashboardDefault = Loadable(lazy(() => import('../views/dashboard/hvc')));
const HVCAnalysis = Loadable(lazy(() => import('../views/dashboard/hvc/dashboard/Dex/analysis')));
const HVCHelpDesk = Loadable(lazy(() => import('../views/utilities/HelpDesk')));
// Fire Dashboard ends here

// Vibration Dashboard starts here
const VibDashboardDefault = Loadable(lazy(() => import('../views/dashboard/vib')));
const VibOverview = Loadable(lazy(() => import('../views/dashboard/vib/dashboardType/SingleView')));
const VibAnalysis = Loadable(lazy(() => import('../views/dashboard/vib/analysis')));
const VibHelpDesk = Loadable(lazy(() => import('../views/utilities/HelpDesk')));
// Vibration Dashboard ends here

//NBSafe Dashboard Starts here
const NBSDashboardDefault = Loadable(lazy(() => import('../views/dashboard/nbs')));
const NBSHelpDesk = Loadable(lazy(() => import('../views/utilities/HelpDesk')));
//NBSafe Dashboard Ends here

//Support Dashboard Starts Here
const TechHelpDesk = Loadable(lazy(() => import('../views/dashboard/admin/helpDeskAdmin')));
//Support Dashboard Ends Here

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));
const NotFound = Loadable(lazy(() => import('../views/dashboard/notFound')));

//-----------------------|| MAIN ROUTING ||-----------------------//
const MainRoutes = () => {
    const location = useLocation();
    const account = useSelector((state) => state.account);
    const [isSessionExpiredModalOpen, setIsSessionExpiredModalOpen] = useState(false);
    const [isSessionPaused, setIsSessionPaused] = useState(false);
    const [isScreenVisible, setIsScreenVisible] = useState(true);
    const logOutUserLastVisitTimeout = 60 * 60 * 1000; // 1 hour in milliseconds
    const pauseUserNoActivityTimeout = 15 * 60 * 1000; // 15 minutes in milliseconds
    const checkUserTokenIsValidInterval = 60 * 1000; // 1 minutes in milliseconds
    const dispatcher = useDispatch();
    const [timeSpentStartTime, setTimeSpentStartTime] = useState(Date.now());


    async function checkTokenStatus(token) {
        try {
            const url = `${configData.API_SERVER}user/check_token`;
            const formData = new FormData();
            const timeSpent = calculateUserSpend()
            formData.append('token', token);
            formData.append('time_spent', timeSpent);
            // Send current time spent while checking token
            const response = await axios.post(url, formData);
            if (response.status === 200 && response.data.active === false) {
                logoutUser();
            }
        } catch (error) {
            console.error('Error checking token status:', error);
        }
    }

    const logoutUser = () => {
        if (localStorage.getItem('is_logged_in')) {
            dispatcher(logOut());
            localStorage.removeItem('lastVisitTime');
            setIsSessionExpiredModalOpen(true);
        }
    };


    useEffect(() => {
        if (account.token !== "" && localStorage.getItem('is_logged_in')) {
            checkTokenStatus(account.token); // Initial check
        }

        const now = new Date();
        const lastVisit = new Date(localStorage.getItem('lastVisitTime'));
        const timeDifference = now - lastVisit;
        if (timeDifference > logOutUserLastVisitTimeout) {
            logoutUser();
        }
    }, []);

    useEffect(() => {
        if (isSessionPaused === false) {
            setTimeSpentStartTime(Date.now());
        } else if (isSessionPaused === true && isScreenVisible === true) {
            sendTimeSpentToServer(account.token);
        }

    }, [isSessionPaused, isScreenVisible])
    const handleUserInteraction = () => {
        const now = new Date();
        localStorage.setItem('lastVisitTime', now.toString());
        setIsSessionPaused(false);// Reset the interaction time on user activity
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleUserInteraction);
        document.addEventListener('keydown', handleUserInteraction);
        return () => {
            document.removeEventListener('mousemove', handleUserInteraction);
            document.removeEventListener('keydown', handleUserInteraction);
        };
    }, [])

    const handleReload = () => {
        window.location.reload();
    };
    const handleLogout = () => {
        window.location.reload();
        setIsSessionExpiredModalOpen(false);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            // navigator.sendBeacon(`${configData.API_SERVER}/v/${isScreenVisible}`);
            if (account.token && localStorage.getItem('is_logged_in') && !isSessionPaused && isScreenVisible) {
                checkTokenStatus(account.token);
            } else if (!localStorage.getItem('is_logged_in')) {
                // This code will stop calculate userSpent when user not logged in
                setTimeSpentStartTime(Date.now());
            }
            const now = new Date();
            const lastVisit = new Date(localStorage.getItem('lastVisitTime'));
            const timeDifference = now - lastVisit;
            if (timeDifference > logOutUserLastVisitTimeout) {
                logoutUser();
            } else if (timeDifference > pauseUserNoActivityTimeout) {
                setIsSessionPaused(true);
            }
        }, checkUserTokenIsValidInterval);

        return () => {
            clearInterval(intervalId);
        };
    }, [isSessionPaused, isScreenVisible, account.token, timeSpentStartTime]);

    const handleUserScreenVisibility = () => {
        const visibility = document.visibilityState;
        if (visibility === "visible") {
            setTimeSpentStartTime(Date.now());
            setIsScreenVisible(true);
        } else if (visibility === "hidden") {
            setIsScreenVisible(false);
            // Send time when the user switches tabs
            if (isSessionPaused === false) {
                sendTimeSpentToServer(account.token);
            }

        }
    };


    useEffect(() => {
        if (account.token) {
            document.addEventListener('visibilitychange', handleUserScreenVisibility);
            return () => {
                document.removeEventListener('visibilitychange', handleUserScreenVisibility);
            };
        }
    }, [timeSpentStartTime, account.token, isSessionPaused])

    const calculateUserSpend = () => {
        const now = Date.now()
        const timeSpent = Math.round((now - timeSpentStartTime) / 1000); // Time in seconds
        setTimeSpentStartTime(now);
        return timeSpent;
    }
    const sendTimeSpentToServer = (token) => {
        const timeSpent = calculateUserSpend() // Time in seconds
        if (timeSpent > 0) {
            try {
                const url = `${configData.API_SERVER}user/track_time`;
                const formData = new FormData();
                formData.append('token', token);
                formData.append('time_spent', timeSpent);
                navigator.sendBeacon(url, formData);
            } catch (error) {
                console.error('Error sending user spent time:', error);
            }
        }
    };


    return isSessionPaused ? (
        <div>
            <SessionPausedMessage onReload={handleReload}/>
            <SessionExpiredModal isOpen={isSessionExpiredModalOpen} onLogout={handleLogout}/>
        </div>
    ) : (
        <div>

            <Switch location={location} key={location.pathname}>
                <Route path="/subscription">
                    <SubscriptionErrorPage/>
                </Route>

                <Route
                    path={['/superAdmin', '/superAdmin/home', '/superAdmin/user', '/superAdmin/device', '/superAdmin/meter', '/superAdmin/imei', '/superAdmin/bms', '/superAdmin/hooter', '/superAdmin/temp', '/superAdmin/hvac', '/superAdmin/wms', '/superAdmin/dg', '/superAdmin/view', '/superAdmin/client', '/superAdmin/site', '/superAdmin/help', '/superAdmin/broadcast']}>
                    <MainLayout type={dashboardType.superAdmin}>
                        <AuthGuard>
                            <Route path="/superAdmin/home" component={SuperAdminPage}/>
                            <Route path="/superAdmin/user" component={SuperAdminUser}/>
                            <Route path="/superAdmin/device" component={SuperAdminDevice}/>
                            <Route path="/superAdmin/meter" component={SuperAdminMeter}/>
                            <Route path="/superAdmin/imei" component={SuperAdminImei}/>
                            <Route path="/superAdmin/bms" component={SuperAdminBms}/>
                            <Route path="/superAdmin/hooter" component={SuperAdminHooter}/>
                            <Route path="/superAdmin/temp" component={SuperAdminTemp}/>
                            <Route path="/superAdmin/hvac" component={SuperAdminHvac}/>
                            <Route path="/superAdmin/wms" component={SuperAdminWms}/>
                            <Route path="/superAdmin/dg" component={SuperAdminDg}/>
                            <Route path="/superAdmin/access" component={SuperAdminMeterAccess}/>
                            <Route path="/superAdmin/view" component={SuperAdminView}/>
                            <Route path="/superAdmin/client" component={SuperAdminClientView}/>
                            <Route path="/superAdmin/site" component={SuperAdminSiteStatus}/>
                            <Route path="/superAdmin/help" component={SuperAdminHelpDesk}/>
                            <Route path="/superAdmin/broadcast" component={SuperAdminBroadcast}/>
                        </AuthGuard>
                    </MainLayout>
                </Route>

                <Route
                    path={['/admin', '/admin/home', '/admin/user', '/admin/meter', '/admin/client', '/admin/help', '/admin/broadcast']}>
                    <MainLayout type={dashboardType.admin}>
                        <AuthGuard>
                            <Route path="/admin/home" component={AdminPage}/>
                            <Route path="/admin/user" component={AdminUser}/>
                            <Route path="/admin/meter" component={AdminMeter}/>
                            <Route path="/admin/client" component={AdminClientView}/>
                            <Route path="/admin/site" component={AdminSiteView}/>
                            <Route path="/admin/help" component={AdminHelpDesk}/>
                            <Route path="/admin/broadcast" component={AdminBroadcast}/>
                            <Route path="/admin/nbaudit" component={AdminNBAudit}/>
                        </AuthGuard>
                    </MainLayout>
                </Route>

                <Route path={['/dg', '/dg/home', '/dg/analysis', '/dg/alarms', '/dg/alarms_list', '/dg/help']}>
                    <MainLayout type={dashboardType.dg}>
                        <AuthGuard>
                            <Route path="/dg/home" component={DGDashboardDefault}/>
                            <Route path="/dg/analysis" component={DGAnalysis}/>
                            <Route path="/dg/alarms" component={DGAlarms}/>
                            <Route path="/dg/alarms_list" component={DGAlarmsList}/>
                            <Route path="/dg/help" component={DGHelpDesk}/>
                        </AuthGuard>
                    </MainLayout>
                </Route>

                <Route path={['/bms', '/bms/home', '/bms/analysis', '/bms/alarms', '/bms/alarms_list', '/bms/help']}>
                    <MainLayout type={dashboardType.bms}>
                        <AuthGuard>
                            <Route path="/bms/home" component={BMSDashboardDefault}/>
                            <Route path="/bms/analysis" component={BMSAnalysis}/>
                            <Route path="/bms/alarms" component={BMSAlarms}/>
                            <Route path="/bms/alarms_list" component={BMSAlarmsList}/>
                            <Route path="/bms/help" component={BMSHelpDesk}/>
                        </AuthGuard>
                    </MainLayout>
                </Route>

                <Route path={['/temp', '/temp/home', '/temp/analysis', '/temp/help']}>
                    <MainLayout type={dashboardType.temp}>
                        <AuthGuard>
                            <Route path="/temp/home" component={TEMPDashboardDefault}/>
                            <Route path="/temp/analysis" component={TEMPAnalysis}/>
                            <Route path="/temp/alarms" component={TEMPAlarms}/>
                            <Route path="/temp/alarms_list" component={TEMPAlarmsList}/>
                            <Route path="/temp/hooter_list" component={TEMPHooterList}/>
                            <Route path="/temp/report" component={TempReport}/>
                            <Route path="/temp/help" component={TempHelpDesk}/>
                        </AuthGuard>
                    </MainLayout>
                </Route>

                <Route path={['/fms', '/fms/home', 'fms/analysis', 'fms/help']}>
                    <MainLayout type={dashboardType.fms}>
                        <AuthGuard>
                            <Route path="/fms/home" component={FMSDashboardDefault}/>
                            <Route path="/fms/help" component={FMSHelpDesk}/>
                        </AuthGuard>
                    </MainLayout>
                </Route>

                <Route path={['/hvc', '/hvc/home', 'hvc/analysis', 'hvc/help']}>
                    <MainLayout type={dashboardType.hvc}>
                        <AuthGuard>
                            <Route path="/hvc/home" component={HVCDashboardDefault}/>
                            <Route path="/hvc/analysis" component={HVCAnalysis}/>
                            <Route path="/hvc/help" component={HVCHelpDesk}/>
                        </AuthGuard>
                    </MainLayout>
                </Route>

                <Route path={['/vib', '/vib/home','vib/overview','vib/analysis', 'vib/help']}>
                    <MainLayout type={dashboardType.vib}>
                        <AuthGuard>
                            <Route path="/vib/home" component={VibDashboardDefault}/>
                            <Route path="/vib/overview" component={VibOverview}/>
                            <Route path="/vib/analysis" component={VibAnalysis}/>
                            <Route path="/vib/help" component={VibHelpDesk}/>
                        </AuthGuard>
                    </MainLayout>
                </Route>

                <Route path={['/wms', '/wms/home', '/wms/analysis', '/wms/help']}>
                    <MainLayout type={dashboardType.wms}>
                        <AuthGuard>
                            <Route path="/wms/home" component={WMSDashboardDefault}/>
                            <Route path="/wms/analysis" component={WMSAnalysis}/>
                            <Route path="/wms/alarms" component={WMSAlarms}/>
                            <Route path="/wms/alarms_list" component={WMSAlarmsList}/>
                            <Route path="/wms/help" component={WMSHelpDesk}/>
                        </AuthGuard>
                    </MainLayout>
                </Route>

                <Route path={['/nbs', '/nbs/home']}>
                    <MainLayout type={dashboardType.nbs}>
                        <AuthGuard>
                            <Route path="/nbs/home" component={NBSDashboardDefault}/>
                            <Route path="/nbs/help" component={NBSHelpDesk}/>
                        </AuthGuard>
                    </MainLayout>
                </Route>

                <Route path={['/trans', '/trans/home']}>
                    <MainLayout type={dashboardType.trans}>
                        <AuthGuard>
                            <Route path="/trans/home" component={TransformerDashboard}/>
                            <Route path="/trans/help" component={TransformerHelpDesk}/>
                        </AuthGuard>
                    </MainLayout>
                </Route>

                <Route path={['/supportAdmin', '/supportAdmin/home']}>
                    <MainLayout type={dashboardType.supportAdmin}>
                        <AuthGuard>
                            <Route path="/supportAdmin/home" component={TechHelpDesk}/>
                        </AuthGuard>
                    </MainLayout>
                </Route>

                <Route
                    path={[
                        '/ems',
                        '/ems/home',
                        // '/ems/comparison',
                        '/ems/mnv',
                        '/ems/alarms',
                        '/ems/alarms_list',
                        "/ems/analysis",
                        '/ems/report_gen',
                        '/ems/kpi',
                        '/ems/kpi_bill',
                        '/ems/ghg',
                        '/ems/support',
                        '/icons/tabler-icons',
                        '/icons/material-icons',
                        '/sample',
                        '/hooter'
                    ]}
                >
                    <MainLayout type={dashboardType.ems}>
                        <AuthGuard>
                            <Route path="/hooter" component={SamplePage}/>
                            <Route path="/ems/home" component={DashboardDefault}/>
                            {/* <Route path="/ems/comparison" component={UtilsComparison} /> */}
                            <Route path="/ems/mnv" component={UtilsMnv}/>
                            <Route path="/ems/alarms" component={UtilsAlarms}/>
                            <Route path="/ems/alarms_list" component={UtilsAlarmsList}/>
                            <Route path="/ems/analysis" component={UtilsAnalysis}/>
                            <Route path="/ems/report_gen" component={UtilsReportGen}/>
                            <Route path="/ems/ghg" component={UtilsGhg}/>
                            <Route path="/ems/kpi" component={UtilsKpi}/>
                            <Route path="/ems/kpi_bill" component={UtilsKpiBill}/>
                            <Route path="/ems/support" component={UtilsSupport}/>
                            <Route path="/ems/icons/tabler-icons" component={UtilsTablerIcons}/>
                            <Route path="/icons/material-icons" component={UtilsMaterialIcons}/>
                            <Route path="/sample-page" component={SamplePage}/>
                        </AuthGuard>
                    </MainLayout>
                </Route>

                <AuthGuard>
                    <Route path="*" component={NotFound}/>
                    <Route path="/notFound" component={NotFound}/>
                </AuthGuard>
            </Switch>
            <SessionExpiredModal isOpen={isSessionExpiredModalOpen} onLogout={handleLogout}/>
        </div>
    );
};

export default MainRoutes;