import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import userRoles from "../../store/userRoles";
//-----------------------|| AUTH GUARD ||-----------------------//

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const location = useLocation();
  const pathName = location.pathname;
  const pathNameSplit = pathName.split("/");
  const account = useSelector((state) => state.account);
  const { isLoggedIn } = account;
  if (!isLoggedIn) {
    if (pathNameSplit[1] === "forgot") {
      return <Redirect to="/forgot" />;
    } else {
      return <Redirect to="/login" />;
    }
  } else if (
    account.user_role === userRoles.superAdmin ||
    account.user_role === userRoles.systemAdmin
  ) {
    if (pathNameSplit[1] === "superAdmin") {
      const validPaths = [
        "/superAdmin/home",
        "/superAdmin/user",
        "/superAdmin/device",
        "/superAdmin/meter",
        "/superAdmin/imei",
        "/superAdmin/access",
        "/superAdmin/wms",
        "/superAdmin/dg",
        "/superAdmin/bms",
        "/superAdmin/temp",
        "/superAdmin/hvac",
        "/superAdmin/hooter",
        "/superAdmin/view",
        "/superAdmin/client",
        "/superAdmin/site",
        "/superAdmin/help",
        "/superAdmin/broadcast",
      ];
      if (validPaths.includes(pathName)) {
        return children;
      } else if (["/superAdmin/", "/superAdmin"].includes(pathName)) {
        return <Redirect to="/superAdmin/home" />;
      } else {
        return <Redirect to="/notFound" />;
      }
    } else {
      return <Redirect to="/superAdmin" />;
    }
  } else if (
    account.user_role === userRoles.admin ||
    account.user_role === userRoles.masterViewer
  ) {
    if (pathNameSplit[1] === "admin") {
      const validPaths = [
        "/admin/home",
        "/admin/user",
        "/admin/meter",
        "/admin/client",
        "/admin/site",
        "/admin/help",
        "/admin/broadcast",
        "/admin/nbaudit"
      ];
      if (validPaths.includes(pathName)) {
        return children;
      } else if (["/admin/", "/admin"].includes(pathName)) {
        return <Redirect to="/admin/home" />;
      } else {
        return <Redirect to="/notFound" />;
      }
    } else {
      return <Redirect to="/admin" />;
    }
  }  else if (
    account.user_role === userRoles.supportAdmin
  ) {
    if (pathNameSplit[1] === "supportAdmin") {
      const validPaths = [
        "/supportAdmin/home","/supportAdmin/help",
      ];
      if (validPaths.includes(pathName)) {
        return children;
      } else if (["/supportAdmin/", "/supportAdmin"].includes(pathName)) {
        return <Redirect to="/supportAdmin/home" />;
      } else {
        return <Redirect to="/notFound" />;
      }
    } else {
      return <Redirect to="/supportAdmin" />;
    }
  } else if (account.user_type === 1 || account.user_type === 3) {
    if (pathName === "/route") {
      if (account.user.dashboard_type === 1) {
        return <Redirect to="/ems/home" />;
      } else if (account.user.dashboard_type === 2) {
        return <Redirect to="/wms/home" />;
      } else if (account.user.dashboard_type === 3) {
        return <Redirect to="/dg/home" />;
      } else if (account.user.dashboard_type === 4) {
        return <Redirect to="/bms/home" />;
      } else if (account.user.dashboard_type === 5) {
        return <Redirect to="/temp/home" />;
      } else if (account.user.dashboard_type === 6) {
        return <Redirect to="/hvc/home" />;
      } else if (account.user.dashboard_type === 7) {
        return <Redirect to="/fms/home" />;
      } else if (account.user.dashboard_type === 8) {
        return <Redirect to="/nbs/home" />;
      } else if (account.user.dashboard_type === 9) {
        return <Redirect to="/trans/home" />;
      } else if (account.user.dashboard_type === 10) {
        return <Redirect to="/vib/home" />;
      } else {
        return <Redirect to="/ems/home" />;
      }
    } else if (pathNameSplit[1] === "ems") {
      const validPaths = [
        "/ems/home",
        "/ems/analysis",
        // "/ems/comparison",
        "/ems/mnv",
        "/ems/alarms",
        "/ems/alarms_list",
        "/ems/report_gen",
        "/ems/kpi",
        "/ems/kpi_bill",
        "/ems/ghg",
        "/ems/support",
        "/ems/transformer",
      ];
      if (validPaths.includes(pathName)) {
        return children;
      } else if (["/ems/", "/ems"].includes(pathName)) {
        return <Redirect to="/ems/home" />;
      } else {
        return <Redirect to="/notFound" />;
      }
    } else if (pathNameSplit[1] === "dg") {
      const validPaths = [
        "/dg/home",
        "/dg/analysis",
        "/dg/alarms",
        "/dg/alarms_list",
        "/dg/help",
      ];
      if (validPaths.includes(pathName)) {
        return children;
      } else if (["/dg/", "/dg"].includes(pathName)) {
        return <Redirect to="/dg/home" />;
      } else {
        return <Redirect to="/notFound" />;
      }
    } else if (pathNameSplit[1] === "bms") {
      const validPaths = [
        "/bms/home",
        "/bms/analysis",
        "/bms/alarms",
        "/bms/alarms_list",
        "/bms/help",
      ];
      if (validPaths.includes(pathName)) {
        return children;
      } else if (["/bms/", "/bms"].includes(pathName)) {
        return <Redirect to="/bms/home" />;
      } else {
        return <Redirect to="/notFound" />;
      }
    } else if (pathNameSplit[1] === "temp") {
      const validPaths = [
        "/temp/home",
        "/temp/analysis",
        "/temp/alarms",
        "/temp/alarms_list",
        "/temp/hooter_list",
        "/temp/report",
        "/temp/help",
      ];
      if (validPaths.includes(pathName)) {
        return children;
      } else if (["/temp/", "/temp"].includes(pathName)) {
        return <Redirect to="/temp/home" />;
      } else {
        return <Redirect to="/notFound" />;
      }
    } else if (pathNameSplit[1] === "fms") {
      const validPaths = ["/fms/home","/fms/help"];
      if (validPaths.includes(pathName)) {
        return children;
      } else if (["/fms/", "/fms"].includes(pathName)) {
        return <Redirect to="/fms/home" />;
      } else {
        return <Redirect to="/notFound" />;
      }
    } else if (pathNameSplit[1] === "hvc") {
      const validPaths = [
        "/hvc/home",
        "/hvc/analysis",
        "/hvc/help",
      ];
      if (validPaths.includes(pathName)) {
        return children;
      } else if (["/hvc/", "/hvc"].includes(pathName)) {
        return <Redirect to="/hvc/home" />;
      } else {
        return <Redirect to="/notFound" />;
      }
    } else if (pathNameSplit[1] === "vib") {
      const validPaths = [
        "/vib/home",
        "/vib/analysis",
        "/vib/report",
        "/vib/help",
      ];
      if (validPaths.includes(pathName)) {
        return children;
      } else if (["/vib/", "/vib"].includes(pathName)) {
        return <Redirect to="/vib/home" />;
      } else {
        return <Redirect to="/notFound" />;
      }
    }  else if (pathNameSplit[1] === "wms") {
      const validPaths = [
        "/wms/home",
        "/wms/analysis",
        "/wms/alarms",
        "/wms/alarms_list",
        "/wms/help",
      ];
      if (validPaths.includes(pathName)) {
        return children;
      } else if (["/wms/", "/wms"].includes(pathName)) {
        return <Redirect to="/wms/home" />;
      } else {
        return <Redirect to="/notFound" />;
      }
    } else if (pathNameSplit[1] === "trans") {
      const validPaths = [
        "/trans/home",
        "/trans/analysis",
        "/trans/help",
      ];
      if (validPaths.includes(pathName)) {
        return children;
      } else if (["/trans/", "/trans"].includes(pathName)) {
        return <Redirect to="/trans/home" />;
      } else {
        return <Redirect to="/notFound" />;
      }
    } else if (pathNameSplit[1] === "nbs") {
      const validPaths = ["/nbs/home","/nbs/help"];
      if (validPaths.includes(pathName)) {
        return children;
      } else if (["/nbs/", "/nbs"].includes(pathName)) {
        return <Redirect to="/nbs/home" />;
      } else {
        return <Redirect to="/notFound" />;
      }
    } else if (pathName === "/subscription") {
      return children;
    } else if (pathName === "/notFound") {
      return children;
    } else if (pathName === "/login") {
      // console.log("----------------------------------148");
      return children;
    } else if (pathName === "/forgot") {
      return children;
    } else if (
      [
        "/admin/home",
        "/superAdmin/home",
        "/admin/user",
        "/superAdmin/user",
      ].includes(pathName)
    ) {
      // this will redirect dashboard when user login from admin/home to the user dashboard . other-wise it will go to notFound section if remove this if block
      return <Redirect to="/" />;
    } else {
      // console.log("----------------------------------151");
      return <Redirect to="/notFound" />;
    }
  } else {
    if (pathName === "/notFound") {
      return children;
    } else {
      // console.log("----------------------------------166");
      return <Redirect to="/notFound" />;
    }
  }
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
