import { createTheme } from "@material-ui/core/styles";

// assets
import colors from "../assets/scss/_themes-vars.module.scss";

// project imports
import { componentStyleOverrides } from "./compStyleOverride";
import { themePalette } from "./palette";
import { themeTypography } from "./typography";

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */
export function theme(customization) {
  const color = colors;

  let themeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    // background: color.primaryLight,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
    customization: customization,
  };

  return createTheme({
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px 0px 0px 0px",
        textAlign: "center",
        fontWeight: "bold",
        color: "#000",
        fontSize: "20px",
        height: "67px",
        border: "1px solid #5A2198",
        "@media (min-width: 600px)": {
          minHeight: "48px",
          padding: "16px 0px 0px 0px",
          textAlign: "center",
          fontWeight: "bold",
          height: "67px",
          color: "#000",
          fontSize: "20px",
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: themeTypography(themeOption),
    components: componentStyleOverrides(themeOption),
  });
}

export default theme;
