// SubscriptionErrorPage.js
import React from "react";
import { useHistory } from "react-router-dom";
import maintenanceGif from "../../assets/images/subscribe.jpg";
import { Button, Container, Typography, Grid } from "@material-ui/core";
import { goBackToAdminView, logOut } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import "./sub.minify.css";
const SubscriptionErrorPage = () => {
  const dispatcher = useDispatch();
  const history = useHistory();
  const account = useSelector((state) => state.account);
  const handleGoHome = () => {
    history.push("/");
  };
  const handleAdminView = () => {
    dispatcher(goBackToAdminView());
    history.push("/");
  };

  const handleLogout = () => {
    dispatcher(logOut());
    history.push("/");
  };
  return (
    <Container
      maxWidth="sm"
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "0px",
      }}
    >
      <section class="page_session">
        <div class="container_session">
          <div class="row">
            <div class="col-sm-12 ">
              <div class="col-sm-12 col-sm-offset-1  text-center">
                <div class="four_zero_four_bg">
                  <Typography variant="h4" gutterBottom className="style1">
                    This Service is not subscribed
                  </Typography>
                  <Typography variant="h4" gutterBottom className="style2">
                    For subscription queries, please call &nbsp;
                    <a href="tel:+91 9619419288">+91 9619419288</a> <br></br>or{" "}
                    <br></br> email sales@nbri.in for more information.
                  </Typography>
                </div>

                <Grid
                  container
                  justifyContent="center"
                  spacing={1}
                  className="contant_box_session"
                >
                  <Grid item xs={12}>
                    <a href="mailto:sales@nbri.in">
                      <img
                        src={maintenanceGif}
                        alt="Subscribe"
                        className="centered-img"
                      />
                    </a>
                  </Grid>
                  <Grid item xs={12} container direction="column" spacing={1}>
                    <Grid item xs={12}>
                      <a href="mailto:sales@nbri.in">
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ borderRadius: "14px" }}
                        >
                          Send Mail
                        </Button>
                      </a>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGoHome}
                        style={{ borderRadius: "14px" }}
                      >
                        Go to Home Page
                      </Button>
                    </Grid>
                    {account.admin !== null && (
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAdminView}
                          style={{ borderRadius: "14px" }}
                        >
                          GO TO ADMIN VIEW
                        </Button>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleLogout}
                        style={{ borderRadius: "14px" }}
                      >
                        Logout
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default SubscriptionErrorPage;
