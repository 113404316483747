/**
 * MUI Components whose styles are override as per theme
 * @param {JsonObject} theme Plain Json Object
 */
export function componentStyleOverrides(theme) {
    return {
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            textTransform: "capitalize",
            borderRadius: "4px",
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            backgroundImage: "none",
          },
          rounded: {
            borderRadius: theme.customization.borderRadius + "px",
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            color: theme.colors.textDark,
            padding: "24px",
          },
          title: {
            fontSize: "1.125rem",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: "24px",
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: "24px",
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            // color: theme.darkTextPrimary,
            color: "#ffffff",
            paddingTop: "10px",
            paddingBottom: "10px",
            "&.Mui-selected": {
              color: theme.menuSelected,
              // backgroundColor: theme.menuSelectedBack,
              backgroundColor: "rgb(121, 75, 172,0.3)",
              borderRadius:'5px',
              "&:hover": {
                // backgroundColor: theme.menuSelectedBack,
                backgroundColor: "rgb(239, 233, 247,0.9)",
              },
              "& .MuiListItemIcon-root": {
                color: theme.menuSelected,
              },
            },
            "&:hover": {
              // backgroundColor: theme.menuSelectedBack,
              // backgroundColor: "#bbd0ff",
              backgroundColor: "rgb(239, 233, 247,0.9)",
              color: theme.menuSelected,
              "& .MuiListItemIcon-root": {
                color: theme.menuSelected,
              },
            },
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            // color: theme.darkTextPrimary,
            color: "#ffffff",
            minWidth: "36px",
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            color: theme.textDark,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            color: theme.textDark,
            "&::placeholder": {
              color: theme.darkTextSecondary,
              fontSize: "0.875rem",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            background: theme.colors.grey50,
            borderRadius: theme.customization.borderRadius + "px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.colors.grey400,
            },
            "&:hover $notchedOutline": {
              borderColor: theme.colors.primaryLight,
            },
            "&.MuiInputBase-multiline": {
              padding: 1,
            },
          },
          input: {
            fontWeight: 500,
            background: theme.colors.grey50,
            padding: "15.5px 14px",
            borderRadius: theme.customization.borderRadius + "px",
            "&.MuiInputBase-inputSizeSmall": {
              padding: "10px 14px",
              "&.MuiInputBase-inputAdornedStart": {
                paddingLeft: 0,
              },
            },
          },
          inputAdornedStart: {
            paddingLeft: 4,
          },
          notchedOutline: {
            borderRadius: theme.customization.borderRadius + "px",
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              color: theme.colors.grey300,
            },
          },
          mark: {
            backgroundColor: theme.paper,
            width: "4px",
          },
          valueLabel: {
            color: theme.colors.primaryLight,
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: theme.divider,
            opacity: 1,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            color: theme.colors.primaryDark,
            background: theme.colors.primary200,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            "&.MuiChip-deletable .MuiChip-deleteIcon": {
              color: "inherit",
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            color: theme.paper,
            background: theme.colors.grey700,
          },
        },
      },
    };
  }
  