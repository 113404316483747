const initialState = {
  data: null,
  error: null,
  isLoading: false,
};

export const meterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_START":
      return { ...state, isLoading: true };
    case "FETCH_SUCCESS":
      return { ...state, data: action.payload, isLoading: false, error: null };
    case "FETCH_ERROR":
      return { ...state, error: action.payload, isLoading: false, data: null };
    case "CLEAR_METER_DATA":
      return { ...state, error: null, isLoading: false, data: null };
    case "GHG_DATA":
      localStorage.removeItem("toDate");
      localStorage.removeItem("fromDate");
      localStorage.removeItem("scope1Data");
      localStorage.removeItem("scope2Data");
      localStorage.removeItem("scope3Data");
      localStorage.removeItem("scope3DataOg");
      localStorage.removeItem("scope1DataOg");
      return state;
    case "MNV_DATA":
      localStorage.removeItem("selectedInput1");
      return state;

    default:
      return state;
  }
};
