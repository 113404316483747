import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Avatar,
  CardContent,
  Chip,
  ClickAwayListener,
  List,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ListItemButton from "@material-ui/core/ListItemButton";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
// import axios from 'axios';

// project imports
import MainCard from "../../../../ui-component/cards/MainCard";
import Transitions from "../../../../ui-component/extended/Transitions";

import { logOut } from "../../../../store/actions";

// assets
import { IconLogout, IconUser, IconSettings } from "@tabler/icons";
import { BrowserView, MobileView } from "react-device-detect";
// style const
const useStyles = makeStyles((theme) => ({
  navContainer: {
    width: "100%",
    maxWidth: "350px",
    minWidth: "200px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  headerAvatar: {
    cursor: "pointer",
    ...theme.typography.mediumAvatar,
    margin: "8px 0 8px 2px !important",
  },
  profileChip: {
    // flexGrow: 1,
    height: "40px",
    alignItems: "center",
    borderRadius: "25px",
    transition: "all .2s ease-in-out",
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.secondary.main,
    '&[aria-controls="menu-list-grow"], &:hover': {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main + "!important",
      color: theme.palette.primary.light,
      "& svg": {
        stroke: theme.palette.primary.light,
      },
    },
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  profileLabel: {
    lineHeight: 1.2,
    padding: "10px",
  },
  listItem: {
    marginTop: "5px",
  },
  cardContent: {
    padding: "16px !important",
  },
  card: {
    backgroundColor: theme.palette.primary.light,
    marginBottom: "16px",
    marginTop: "16px",
  },
  searchControl: {
    width: "100%",
    paddingRight: "8px",
    paddingLeft: "16px",
    marginBottom: "16px",
    marginTop: "16px",
  },
  startAdornment: {
    fontSize: "1rem",
    color: theme.palette.grey[500],
  },
  flex: {
    display: "flex",
  },
  name: {
    marginLeft: "2px",
    fontWeight: 400,
  },
  ScrollHeight: {
    height: "100%",
    maxHeight: "calc(100vh - 250px)",
    overflowX: "hidden",
  },
  badgeWarning: {
    backgroundColor: theme.palette.warning.dark,
    color: "#fff",
  },
  customTypography: {
    [theme.breakpoints.down("lg")]: {
      fontSize: "9px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "9px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "9px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "9px",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "8px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "8px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "8px",
    },
  },
}));

//-----------------------|| PROFILE MENU ||-----------------------//

const ProfileSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const account = useSelector((state) => state.account);
  const username = account.user?.name || "";
  const isLongUsername = username.length > 10;
  const history = useHistory();
  const dispatcher = useDispatch();

  const [sdm, setSdm] = React.useState(true);
  const [value, setValue] = React.useState("");
  const [notification, setNotification] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleLogout = () => {
    dispatcher(logOut());
  };
  const handlePassword = () => {
    dispatcher(logOut());
    history.push("/forgot"); // Redirect to /forgot after logout
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <React.Fragment>
      <BrowserView>
        <Tooltip title={account.user?.name || ""} placement="bottom">
          <Chip
            classes={{ label: classes.profileLabel }}
            className={classes.profileChip}
            icon={
              <Avatar
                src={
                  account.user ? (
                    <Typography
                      variant="h4"
                      // style={
                      //   isLongUsername
                      //     ? { fontSize: "8px" }
                      //     : { fontSize: "14px" }
                      // }
                    >
                      {account.user.name ? account.user.name[0] : ""}
                    </Typography>
                  ) : null
                }
                className={classes.headerAvatar}
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                color="inherit"
              />
            }
            label={
              <Typography
                variant="h4"
                style={{
                  fontSize: "16px",
                  padding: "0px 10px 0px 0px",
                  color: "#fff",
                }}
              >
                {account.user?.name || ""}
              </Typography>
            }
            variant="outlined"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="primary"
          />
        </Tooltip>
      </BrowserView>
      <MobileView>
        <Tooltip title={account.user?.name || ""} placement="bottom">
          <Chip
            classes={{ label: classes.profileLabel }}
            className={classes.profileChip}
            icon={
              <Avatar
                src={
                  account.user ? (
                    <Typography variant="h4">
                      {account.user.name ? account.user.name[0] : ""}
                    </Typography>
                  ) : null
                }
                className={classes.headerAvatar}
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                color="inherit"
              />
            }
            label={
              <Typography
                variant="h4"
                style={{
                  fontSize: "16px",
                  padding: "0px 10px 0px 0px",
                  color: "#fff",
                }}
              >
                {account.user && account.user.name
                  ? account.user.name.slice(0, 1).toUpperCase()
                  : ""}
              </Typography>
            }
            variant="outlined"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="primary"
          />
        </Tooltip>
      </MobileView>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14], //For Logout card width float
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <CardContent className={classes.cardContent}>
                    <PerfectScrollbar className={classes.ScrollHeight}>
                      <List component="nav" className={classes.navContainer}>
                        <ListItemButton
                          className={classes.listItem}
                          sx={{
                            borderRadius: customization.borderRadius + "px",
                          }}
                        >
                          <ListItemIcon>
                            <IconUser
                              stroke={1.5}
                              size="1.5rem"
                              style={{ color: "#5e35b1" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">
                                {account.user?.name || ""}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          className={classes.listItem}
                          sx={{
                            borderRadius: customization.borderRadius + "px",
                          }}
                          onClick={handlePassword}
                        >
                          <ListItemIcon>
                            <IconSettings
                              stroke={1.5}
                              size="1.5rem"
                              style={{ color: "#5e35b1" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">
                                Change Password
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          className={classes.listItem}
                          sx={{
                            borderRadius: customization.borderRadius + "px",
                          }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout
                              stroke={1.5}
                              size="1.5rem"
                              style={{ color: "#ef4350" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2" color="error">
                                Logout
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </PerfectScrollbar>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default ProfileSection;
